import React from 'react';
import { Layout } from 'antd';
import FeatherIcon from 'feather-icons-react';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import SEO from '../../components/Seo';
import style from './impressum.module.less';

const Impressum = () => (
  <Layout className="outerPadding">
    <Layout className="container">
      <SEO
        title="Product Owner"
        description=""
        path="/product-owner"
        keywords={['Gerald', 'Drausinger', 'ThinkBright', 'Agile Coaching', 'Product Owner', 'Scrum Master', 'Business Analyse', 'Projektmanagement']}
      />
      <Header />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Impressum</h1>
        </div>
        <div className={`${style.impressum}`}>
          <p>
            ThinkBright | IT- und Unternehmensberatung
            <br />
            Gerald Drausinger
            <br />
            Weidengasse 12
            <br />
            2601 Sollenau
          </p>
          <p>
            <a
              href="mailto:&#111;&#102;&#102;&#105;&#99;&#101;&#64;&#116;&#104;&#105;&#110;&#107;&#98;&#114;&#105;&#103;&#104;&#116;&#46;&#97;&#116;"
              target="_top"
            >
              <span><FeatherIcon size="19" icon="mail" /></span>
              &nbsp;
              <span className={style.emailHider}>@</span>
            </a>
            <br />
            <a
              href="tel:&#43;&#52;&#51;&#54;&#55;&#48;&#54;&#48;&#55;&#56;&#49;&#49;&#49;"
              target="_top"
            >
              <span><FeatherIcon size="19" icon="smartphone" /></span>
              +43 670 607 8111
            </a>
          </p>
          <p>
            UID: ATU72358316
          </p>
          <p>
            Gewerbe: Unternehmensberatung einschließlich der Unternehmensorganisation
            <br />
            Mitglied der Wirtschaftskammer Fachgruppe UBIT
          </p>
          <p>
            Verbraucher haben die Möglichkeit, Beschwerden an die
            Online-Streitbeilegungsplattform der EU zu richten:
            &nbsp;
            <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>
            . Sie  können  allfällige  Beschwerde  auch  an  die oben angegebene E-Mail-Adresse
            richten.
          </p>
        </div>
        <div>
          <h1 className="titleSeparate">Datenschutzerklärung</h1>
          <p>
            Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir
            verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen
            Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren
            wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.
          </p>
          <p>
            Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und Ende der Sitzung
            für die Dauer dieser Sitzung erfasst. Dies ist technisch bedingt und stellt
            damit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar. Soweit im
            Folgenden nichts anderes geregelt wird, werden diese Daten von uns nicht
            weiterverarbeitet.
          </p>
          <h2>Kontakt mit uns</h2>
          <p>
            Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen,
            werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von
            Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </p>
        </div>
      </SidebarWrapper>
    </Layout>
  </Layout>
);

export default Impressum;
